import React from 'react';
import { Block, Loader } from 'components/atoms';
import withDetectUrlChange from 'hocs/withDetectUrlChange';
import InfiniteScroll from 'react-infinite-scroller';
import fireApi from 'appFirebase/fireApi';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import { animateScroll } from 'react-scroll/modules';
import CircularProgress from '@material-ui/core/CircularProgress/index';
import { BrandList, BrandCategoryButton, Header } from 'components/molecules';
import styled from '@emotion/styled';

// REDUX
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { getCategories } from 'modules/category/actions';
import {
  selectCategories,
  selectCategoriesStatus
} from 'modules/category/selectors';

import get from 'lodash/get';
import withTheme from 'hocs/withTheme';
import firebase from '@firebase/app';

const LIMIT = 8;

const StyledBlock = styled('div')({
  background: '#fafbfc',
  height: '100%',
  '.categoryBlock': {
    overflowX: 'auto',
    background: 'white',
    borderBottom: '1px solid #eaeaeb',
    position: 'sticky',
    zIndex: 1,
    top: 0,
    '-webkit-overflow-scrolling': 'touch',
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  '.categoryButtonBlock': {
    flex: '0 0 auto'
  }
});

class ShopCategory extends React.Component {
  state = {
    items: Array.from({ length: 20 }),
    hasMore: true,
    userId: null
  };

  scrollToTop = () =>
    animateScroll.scrollToTop({
      smooth: true,
      duration: 700
    });

  componentDidMount() {
    const liff = window.liff;
    liff.init(async data => {
      let profile = await liff.getProfile();
      this.setState({
        userId: profile.userId
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    const { location: { search }, brands } = this.props;
    const { location: { search: nextSearch } } = nextProps;
    if (search !== nextSearch) {
      const { categoryId } = qs.parse(nextSearch, {
        ignoreQueryPrefix: true
      });
      const list = brands[`categoryId=${categoryId}`];
      this.setState({ hasMore: !list || list.length % LIMIT === 0 });
    }
  }

  fetchMoreData = () => {
    const {
      brands,
      activeCategory,
      getBrandsByCategory,
      selectedCategory,
      categories
    } = this.props;
    //console.log('activeCategory', activeCategory);
    const category = categories.find(item => item.id === selectedCategory);

    if (brands[activeCategory]) {
      let brandGroup = brands[activeCategory];
      const lastBrandId = brandGroup[brandGroup.length - 1].id;
      fireApi
        .fetchBrands({
          categoryId: category.id,
          brandId: lastBrandId,
          limit: LIMIT
        })
        .then(newBrands => {
          if (newBrands.length < LIMIT) {
            this.setState({ hasMore: false });
            getBrandsByCategory(activeCategory, newBrands);
          } else {
            getBrandsByCategory(activeCategory, newBrands);
          }
        });
    }
  };

  renderBrandLists = () => {
    const { brands, activeCategory, history, selectedCategory } = this.props;
    const { userId } = this.state;
    const liff = window.liff;
    if (!isEmpty(brands) && get(brands, activeCategory))
      return (
        <BrandList.Group
          brands={brands[activeCategory]}
          liff={liff}
          userId={userId}
        />
      );
    else return null;
  };

  render() {
    const {
      categories,
      getCategoriesStatus,
      brands,
      selectedCategory,
      updateSelectedCategory
    } = this.props;
    const { hasMore } = this.state;
    return (
      <Block>
        <Header />
        <Block maxWidth={['inherit', 700, 960, 1200]} mx={'auto'}>
          {categories.length === 0 &&
            getCategoriesStatus.isPending && (
              <Loader text={'กำลังโหลดข้อมูล'} />
            )}
          {categories.length > 0 &&
            brands && (
              <StyledBlock>
                <Block.Flex
                  p={15}
                  flexWrap={'nowrap'}
                  mt={70}
                  className={'categoryBlock'}>
                  {categories.map((category, index) => (
                    <Block
                      className={'categoryButtonBlock'}
                      mr={15}
                      onClick={() => {
                        this.scrollToTop();
                        updateSelectedCategory(category.id);
                      }}
                      key={index}>
                      <BrandCategoryButton
                        image={
                          category.imagePath
                        }
                        name={category.name}
                        total={category.total}
                        active={selectedCategory === category.id}
                      />
                    </Block>
                  ))}
                </Block.Flex>
                {!isEmpty(this.props.brands) &&
                  categories.length > 0 && (
                    <InfiniteScroll
                      pageStart={0}
                      loadMore={() => this.fetchMoreData()}
                      hasMore={hasMore}
                      loader={
                        <Block.Flex
                          key={'loader'}
                          justifyContent={'center'}
                          p={20}>
                          <CircularProgress size={20} thickness={5} />
                        </Block.Flex>
                      }>
                      {this.renderBrandLists()}
                    </InfiniteScroll>
                  )}
                {!hasMore && <Block pt={60} />}
              </StyledBlock>
            )}
        </Block>
      </Block>
    );
  }
}

export default compose(
  connect(
    state => ({
      categories: selectCategories(state),
      getCategoriesStatus: selectCategoriesStatus(state)
    }),
    dispatch =>
      bindActionCreators(
        {
          getCategories
        },
        dispatch
      )
  ),
  withDetectUrlChange,
  withTheme()
)(ShopCategory);
