import { fireStoreService } from './config'
const brandCollection = fireStoreService.makeCollection('brands')

export const fetchBrands = ({
  categoryId,
  brandId,
  sortBy = 'createdAt',
  limit,
}) =>
  brandCollection.find({
    queries: [
      ['category.id', '==', categoryId],
      ['activate', '==', true],
    ],
    orderBy: [sortBy, 'asc'],
    limit,
    afterDocument: brandId,
  })

export default {
  fetchBrands,
}
