import React from 'react';

// REDUX
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import {
  getBrandsByCategory,
  changeActiveCategory
} from 'modules/brand/actions';
import {
  selectBrandsQuery,
  selectBrandsQueryStatus,
  selectActiveCategory
} from 'modules/brand/selectors';
import { getCategories } from 'modules/category/actions';
import { selectCategories } from 'modules/category/selectors';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
// import { withRouter } from 'react-router';
import fireApi from 'appFirebase/fireApi';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { navigate } from 'gatsby';

const DetectUrlChange = WrappedComponent => {
  class WithDetectUrlChange extends React.Component {
    state = {
      brandShop: null,
      selectedCategory: null
    };
    componentDidMount() {
      const { brands, categories, location } = this.props;
      console.log('location', location);
      if (categories.length === 0 && isEmpty(brands)) {
        this.props.getCategories().then(data => {
          const { category, stringify } = this.setUpParseAndStringify(
            this.props.location.search,
            data.value
          );
          if (isEmpty(brands) && !location.search) {
            this.updateSelectedCategory(data.value[0].id);
          }
          if (get(category, 'id')) {
            this.props.changeActiveCategory(stringify);
            return this.getBrand(stringify, category.id);
          }
        });
      }
    }

    componentWillReceiveProps(nextProps) {
      const { location: { search }, brands, categories } = this.props;
      const { location: { search: nextSearch } } = nextProps;
      if (search !== nextSearch) {
        const { category, stringify } = this.setUpParseAndStringify(
          nextSearch,
          categories
        );
        if (get(category, 'id')) {
          if (!get(brands, stringify)) {
            return fireApi
              .fetchBrands({
                categoryId: category.id,
                limit: 8
              })
              .then(brands => {
                this.props.changeActiveCategory(stringify);
                return this.props.getBrandsByCategory(stringify, brands);
              });
          } else {
            this.props.changeActiveCategory(stringify);
          }
        }
      }
    }

    setUpParseAndStringify = (paramPath, categoryTypes) => {
      const parse = qs.parse(paramPath, {
        ignoreQueryPrefix: true
      });
      const category = categoryTypes.find(item => item.id === parse.categoryId);
      const stringify = qs.stringify(
        parse,
        { sort: this.alphabetical },
        { addQueryPrefix: true }
      );
      const stringifyWithOutBrand = qs.stringify(
        omit(parse, ['brandId']),
        { sort: this.alphabetical },
        { addQueryPrefix: true }
      );
      return { parse, category, stringify, stringifyWithOutBrand };
    };

    alphabetical = (a, b) => {
      return a.localeCompare(b);
    };

    getBrand = (stringify, categoryId) => {
      return fireApi
        .fetchBrands({
          categoryId: categoryId,
          limit: 8
        })
        .then(brands => {
          this.props.getBrandsByCategory(stringify, brands);
          return brands;
        });
    };

    updateSelectedCategory = categoryId => {
      let parse = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      });
      parse.categoryId = categoryId;
      const stringify = qs.stringify(
        parse,
        { sort: this.alphabetical },
        { addQueryPrefix: true }
      );
      return navigate(`/shop-category?${stringify}`, { replace: true });
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          // router={this.props.history}
          brandShop={this.state.brandShop}
          selectedCategory={qs.parse(this.props.activeCategory).categoryId}
          updateSelectedCategory={this.updateSelectedCategory}
        />
      );
    }
  }
  return compose(
    connect(
      state => ({
        brands: selectBrandsQuery(state),
        getBrandStatus: selectBrandsQueryStatus(state),
        activeCategory: selectActiveCategory(state),
        categories: selectCategories(state)
      }),
      dispatch =>
        bindActionCreators(
          {
            getBrandsByCategory,
            changeActiveCategory,
            getCategories
          },
          dispatch
        )
    )
    // withRouter
  )(WithDetectUrlChange);
};

export default DetectUrlChange;
